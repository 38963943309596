import request from '@/utils/request'

export function queryExchangeList(parameter) {
  return request({
    url: '/member/cash/exchange/page',
    method: 'post',
    data: parameter
  })
}


export function queryExchangeHead(parameter) {
  return request({
    url: '/member/cash/exchange/statistics',
    method: 'post',
    data: parameter
  })
}


export function auditExchange(parameter) {
  return request({
    url: '/member/cash/exchange/audit',
    method: 'post',
    data: parameter
  })
}

//删除申请批量申请
export function deleteExchange(parameter) {
  return request({
    url: '/member/cash/exchange/delete/' + parameter.id,
    method: 'delete'
  })
}

//删除申请详情获取
export function queryDetail(parameter) {
  return request({
    url: '/member/cash/exchange/detail/' + parameter.id,
    method: 'get',
  })
}

// 分页查询申请的直付订单
export function queryExchangeOrderList(parameter) {
  return request({
    url: '/member/cash/exchange/order/page',
    method: 'post',
    data: parameter
  })
}

//手动结束完成申请
export function exchangeFinish(parameter) {
  return request({
    url: '/member/cash/exchange/finished/'+parameter.id,
    method: 'post',
    params: parameter
  })
}





