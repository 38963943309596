<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='12' :md='6' :xl='6' :style="{ marginBottom: '24px' }">
        <a-card :loading='loading'>
          <div class="card_content">
            <a-row>
              <a-col :span="12"><span class="card_title">待审核申请：</span></a-col>
              <a-col :span="12"><span class="card_title">待审核积分：</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="12"><span class="color-red">{{dashboard.waitAuditCount||0}}</span></a-col>
              <a-col :span="12"><span class="color-red">{{dashboard.waitAuditAmount||0}}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='6' :xl='6' :style="{ marginBottom: '24px' }">
        <a-card :loading='loading'>
          <div class="card_content">
            <a-row>
              <a-col :span="12"><span class="card_title">已通过：</span></a-col>
              <a-col :span="12"><span class="card_title">已通过积分：</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="12"><span class="color-red">{{ dashboard.passAuditCount||0 }}</span></a-col>
              <a-col :span="12"><span class="color-red">{{ dashboard.passAuditAmount||0 }}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='masterName'>
        <a-input v-model='form.masterName' style="width: 200px" placeholder="商户负责人" />
      </a-form-model-item>

      <a-form-model-item prop='mobile'>
        <a-input v-model='form.mobile' style="width: 200px" placeholder="手机号" />
      </a-form-model-item>

      <a-form-model-item prop='areaShortName'>
        <a-input v-model='form.areaShortName' style="width: 200px" placeholder="分公司简称" />
      </a-form-model-item>

      <a-form-model-item prop='status'>
        <a-select v-model="form.status" placeholder="状态" style="width: 250px">
          <a-select-option :value="undefined">
            全部
          </a-select-option>
          <a-select-option :value="1">
            待审核
          </a-select-option>
          <a-select-option :value="2">
            兑换中
          </a-select-option>
          <a-select-option :value="3">
            不通过
          </a-select-option>
          <a-select-option :value="4">
            已完成
          </a-select-option>
        </a-select>
      </a-form-model-item>

<!--      <a-form-model-item prop='exchangeStatus'>-->
<!--        <a-select v-model="form.exchangeStatus" placeholder="兑换状态" style="width: 250px">-->
<!--          <a-select-option :value="undefined">-->
<!--            全部-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="1">-->
<!--            进行中-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="2">-->
<!--            已完成-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

      <a-form-model-item prop='start' >
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true,
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' :columns='columns' :scroll='{ x: "100%"}' :data='loadData' :pageSize="40">
        <span slot='status1' slot-scope='text, record'>
          <template>
             <a-badge v-if='text=="1"' dot status='default' :text='"待审核"' />
             <a-badge v-if='text=="2"' dot status='success' :text='"兑换中"' />
             <a-badge v-if='text=="3"' dot status='error' :text='"不通过"' />
             <a-badge v-if='text=="4"' dot status='success' :text='"已完成"' />
          </template>
        </span>
<!--        <span slot='exchangeStatus' slot-scope='text, record'>-->
<!--          <template>-->
<!--             <a-badge v-if='text=="0"' dot status='default' :text='"未开始"' />-->
<!--             <a-badge v-if='text=="1"' dot status='default' :text='"进行中"' />-->
<!--             <a-badge v-if='text=="2"' dot status='success' :text='"已完成"' />-->
<!--          </template>-->
<!--        </span>-->
        <span slot='action' slot-scope='text, record'>
          <template>
            <span>
                  <a-divider type='vertical' />
                  <a @click='handleDetail(record)'>详情</a>
            </span>

            <span v-if="record.auditStatus=='1'">
                  <a-divider type='vertical' />
                  <a @click='initShenHe(record,"1")'>审核</a>
            </span>

            <span v-if='record.auditStatus!="2"'>
                  <a-divider type='vertical' />
                  <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                                              @confirm='handleDelete(record)'>
                    <a href='#'>删除</a>
                  </a-popconfirm>
            </span>

          </template>
        </span>
        <span slot='avatar' slot-scope='text'>
          <template>
            <a-avatar v-if='text' :src='text' />
            <a-avatar v-else icon='user' />
          </template>
        </span>
        <span slot='status' slot-scope='text'>
          <template>
            <a-badge v-if='text==0' dot status='default' :text='"注销"' />
            <a-badge v-if='text==1' dot status='processing' :text='"有效"' />
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='批量兑换申请审核' :destroyOnClose='true' :maskClosable='true' width="20%"
             @cancel='()=>this.deliver1.visible=false' @ok="updateCashBalance"
             :visible='deliver1.visible' :confirmLoading='deliver1.loading'>

<!--      <a-descriptions :column="1" style="width: 90%;margin-bottom: 20px;margin-right: auto;margin-left: auto;" >-->
<!--        <a-descriptions-item label="商户负责人">{{ this.deliver1.form.masterName }}</a-descriptions-item>-->
<!--        <a-descriptions-item label="分公司">{{ this.deliver1.form.areaShortName }}</a-descriptions-item>-->
<!--        <a-descriptions-item label="兑换积分">{{ this.deliver1.form.amount }}</a-descriptions-item>-->
<!--      </a-descriptions>-->

      <a-form-model ref='form' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 14 }'>
        <a-form-model-item label='商户负责人' prop='masterName'>
          {{ this.deliver1.form.masterName }}
        </a-form-model-item>
        <a-form-model-item label='分公司' prop='areaShortName'>
          {{ this.deliver1.form.areaShortName }}
        </a-form-model-item>
        <a-form-model-item label='兑换积分' prop='amount'>
          {{ this.deliver1.form.amount }}
        </a-form-model-item>

        <a-form-model-item label='服务积分' prop='rate' v-if="deliver1.form.value1!='3'">
          <a-radio-group v-model="deliver1.form.rate"  >
            <a-radio value="1" style="text-align: center;">
              扣
            </a-radio>
            <a-divider type='vertical' />
            <a-radio value="0" style="text-align: center;">
              不扣
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='审核' prop='value1'>
          <a-radio-group v-model="deliver1.form.value1"  >
            <a-radio value="2" style="text-align: center;">
                    通过
            </a-radio>
            <a-divider type='vertical' />
            <a-radio value="3" style="text-align: center;">
                   不通过
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="deliver1.form.value1=='3'" label='不通过原因' prop='remark'>
          <a-input v-model='deliver1.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryOperators, removeOperator, memberHeardData, putPause,putExchange} from '@/api/operator'
import moment from "moment";
import {auditExchange, deleteExchange, queryExchangeHead, queryExchangeList} from "@/api/branch";

const columns = [
  {
    width: 90,
    title: '商户负责人',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  {
    width: 90,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 100,
    title: '分公司简称',
    dataIndex: 'areaShortName',
    scopedSlots: { customRender: 'areaShortName' }
  },
  {
    width: 100,
    title: '申请时间',
    dataIndex: 'createTime'
  },
  {
    width: 70,
    title: '兑换积分',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' },
    sorter: true,
  },
  {
    width: 70,
    title: '审核时间',
    dataIndex: 'auditTime',
    scopedSlots: { customRender: 'auditTime' },
    sorter: true,
  },
  {
    width: 70,
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status1' },
    sorter: true,
  },
  // {
  //   width: 70,
  //   title: '兑换状态',
  //   dataIndex: 'exchangeStatus',
  //   scopedSlots: { customRender: 'exchangeStatus' }
  // },
  {
    width: 80,
    title: '已兑换',
    dataIndex: 'usedAmount',
    scopedSlots: { customRender: 'usedAmount' }
  },
  {
    width: 120,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const formatType = 'YYYY-MM-DD HH:mm:ss';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59:59';

export default {
  name: 'OperatorList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      loading: true,
      dashboard: {}, //表头用户数统计
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        name: "",
        start: baseStart,
        end: baseEnd,
      },
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        // parameter.size=40;
        let param = Object.assign(parameter, this.queryParam,this.form);
        // debugger;
        return queryExchangeList(param)
          .then(datum => {
            return datum
          })
      },
      deliver1: { visible: false,
        form: {
        },
        rules: {
          value1: [{ required: true, message: '请选择审核状态', trigger: 'change' },],
          remark: [{ required: true, message: '请填写审核备注', trigger: 'change' },],
          rate: [{ required: true, message: '请选择是否扣除手续费', trigger: 'change' },],
        },
        loading: false },
    }
  },
  created() {
      this.getHeaderData();
  },
  methods: {
    getTypeNameStr(type,record) {
      if(record.shareholder=='1'){
        //股东判断
        return '股东'
      }
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        return '服务中心';
      }else if(type=='president') {
        return '运营总裁';
      }else if(type=='normal'){
        // debugger;
        if(record.level=='center'){
          return '备选服务';
        }
        return '用户';
      }else{
        return '';
      }
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
      // debugger;
      this.loading=true;
      this.getHeaderData();
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm:ss");
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm:ss");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleDelete(record) {
      deleteExchange({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDetail(record) {
      this.$router.push('/branch/detail?id='+record.id)
    },
    getHeaderData(){
      queryExchangeHead(Object.assign({}, this.form)).then(result =>{
        let d = moment(new Date()).format('YYYY-MM-DD hh:mm:ss');
        if(result){
          this.dashboard = result;
        }

        this.loading = false;
      });
    },

    //批量审核弹出
    initShenHe(record){
      this.deliver1.form = Object.assign(record);
      // this.deliver1.form.value1 = '2';
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    updateCashBalance(){
      this.$refs.form.validate((result) => {
        if(result){
          let _from = this.deliver1.form;
          let params = Object.assign({
              id:this.deliver1.form.id,
              auditStatus:this.deliver1.form.value1,
              rate:this.deliver1.form.rate,
            });
          if(this.deliver1.form.value1=='3'){
            params.auditRemark = this.deliver1.form.remark;
          }
          if(!this.deliver1.form.rate){
            params.rate = 0;
          }
          debugger;
          this.deliver1.loading = true;
          auditExchange(params).then(result=>{
            this.$message.success('操作成功')
            this.deliver1.visible = false
            this.deliver1.loading = false;
            this.$refs.table.refresh(true)
          })

        }

      })
    }
  }
}
</script>
